/**
 * Returns a function that calls the passed arguments in reverse order, e.g.
 */
const compose =
  <T extends ((input: any) => any)[]>( // eslint-disable-line @typescript-eslint/no-explicit-any
    ...functions: T
  ) =>
  <V extends ReturnType<T[number]>>(value: V) => {
    return functions.reduceRight((previousValue, currentValue) => {
      return currentValue(previousValue);
    }, value);
  };

export default compose;
