/**
 * In web, we can not get device id of user
 * so, we will generate and persist into cookie
 *
 * Lotus is follow Cacheability principle,
 * So, we don't use device from server side
 */
import Cookie, { getExpireTimeByYears } from './cookie';
import isServer from './is-server';
import { randomUUIDOnClient } from './randomUUID';

export const DEVICE_ID_COOKIE_KEY = 'DEVICE_ID'; // make sure to use the same key with Alita.

export const getDeviceId = () => {
  if (isServer()) {
    throw new Error('Device ID dose not work on server side');
  }

  const cookie = new Cookie();
  const deviceId = cookie.get(DEVICE_ID_COOKIE_KEY);

  return deviceId;
};

export const generateDeviceId = () => {
  if (isServer()) {
    throw new Error('Device ID dose not work on server side');
  }

  const cookie = new Cookie();

  const deviceId = randomUUIDOnClient();
  const expireTime = getExpireTimeByYears(2);

  cookie.set(DEVICE_ID_COOKIE_KEY, deviceId, expireTime.toUTCString());

  return deviceId;
};
