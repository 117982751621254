export const enum PAGES_TYPES {
  homepage = 'homepage',
  cat = 'cat',
  subcat = 'subcat',
  product = 'product',
  cart = 'cart',
  checkout = 'checkout',
  success = 'success',
  brand = 'brand',
  static = 'static',
  search = 'search',
  error = '404error',
  segment = 'segment',
  magazine = 'magazine',
  boutique_catalog = 'boutique catalog',
  boutique = 'boutique',
  wishlist = 'wishlist',
  categories = 'categories',
  account = 'account',
  product_index = 'product index',
  login = 'login',
  register = 'register',
  catalog = 'catalog',
  zis = 'zis',
  unknown = 'unknown',
}
