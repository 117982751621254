import isServer from './is-server';

/**
 * Developers can specify arbitrary data in a meta tag with the name
 * "z_page_data". This is required for PDV and PDVNotFound pages as they share
 * the same URL. The meta tag is used to differentiate between the two pages.
 *
 * ```TS
 * <meta
 *   name="z_page_data"
 *   content={JSON.stringify({
 *     trackPageCallTitle: 'Page not found',
 *     isNotFound: true,
 *   })}
 * />
 * ```
 */
export const getPageData = (): Record<string, string | number | boolean> => {
  if (isServer()) {
    return {};
  }

  const elPageData = document.querySelector('meta[name="z_page_data"]');

  if (elPageData) {
    try {
      return JSON.parse(elPageData.getAttribute('content') || '{}');
    } catch {}
  }

  return {};
};

export const getTrackPageCallTitle = () => {
  const pageData = getPageData();

  return typeof pageData.trackPageCallTitle === 'string'
    ? pageData.trackPageCallTitle || document.title
    : document.title;
};

export const isNotFound = () => {
  return !!getPageData().isNotFound;
};
