import { StateCreator } from 'zustand';
import {
  flattenPromoObject,
  mapToPromotionList,
  mapToSkuCoupons,
  refineManualVCs,
} from 'components/promotion/utils';
import { CartStore } from '../types';
import { PromoSlice } from './types';

export const createPromoSlice = (preloadedState?: Partial<PromoSlice>) => {
  const createSlice: StateCreator<CartStore, [['zustand/mutative', never]], [], PromoSlice> = (
    set,
    get,
  ) => ({
    promoParams: {},
    activeSku: null,
    selectedPromos: [],
    isSelectingStackblePromo: false,
    ...preloadedState,

    // Re-compute promoParams based on cart response
    fetchCartSuccess: ({ GroupedCartItems, CartPromoSection, PromoAppliedDetail, ManualVcs }) => {
      set((state) => {
        const skuCoupons = mapToSkuCoupons(GroupedCartItems);
        const ids = PromoAppliedDetail ? flattenPromoObject(PromoAppliedDetail) : [];
        const promotionList = mapToPromotionList(CartPromoSection, ids);

        state.promoParams.skuCoupons = skuCoupons;
        state.promoParams.promotionList = JSON.stringify(promotionList);
        state.promoParams.manualVCs = refineManualVCs(ManualVcs, promotionList);

        state.hideItemPromo();
      });
    },

    showCartPromo: (appliedPromos) => {
      set((state) => {
        state.activeSku = null;
        state.selectedPromos = appliedPromos ? flattenPromoObject(appliedPromos) : [];
      });
    },

    showItemPromo: (item) => {
      set((state) => {
        const { PromoAppliedDetail } = item;

        state.activeSku = item;
        state.selectedPromos = PromoAppliedDetail ? flattenPromoObject(PromoAppliedDetail) : [];
      });
    },

    hideItemPromo: () => {
      set((state) => {
        state.activeSku = null;
      });
    },

    selectNonStackPromo: (id) => {
      set((state) => {
        // Tmp fix for BVC selection
        // TODO: Create separate state for selected BVC
        const bvcPrefix = 'bonus_voucher';
        const refinedPromos = state.selectedPromos.filter((p) => {
          const isBVC = p.startsWith(bvcPrefix);

          return id.startsWith(bvcPrefix) ? !isBVC : isBVC;
        });

        state.selectedPromos = refinedPromos.concat(id);
        state.isSelectingStackblePromo = false;
      });
    },

    selectStackPromo: (id) => {
      set((state) => {
        if (state.selectedPromos.includes(id)) {
          state.selectedPromos = state.selectedPromos.filter((p) => p !== id);
        } else if (state.isSelectingStackblePromo) {
          state.selectedPromos.push(id);
        } else {
          state.selectedPromos = [id];
        }

        state.isSelectingStackblePromo = true;
      });
    },

    clearSelectedPromos: () => {
      set((state) => {
        state.selectedPromos = [];
      });
    },

    trackingOnPromoApplied: (cart) => {
      const params = { ...get().promoParams, applySku: get().activeSku?.SimpleSku };

      import('pages/cart/trackings/trackingOnCartPromotionApplied').then(
        ({ trackingOnCartPromotionApplied }) => {
          trackingOnCartPromotionApplied({
            cart,
            params,
            promoIdentifier: get().selectedPromos[0],
          });
        },
      );
    },
  });

  return createSlice;
};
