export type Segment =
  | 'women'
  | 'men'
  | 'kids'
  | 'beauty'
  | 'home'
  | 'lifestyle'
  | 'hobbies'
  | 'luxury'
  | 'sports';

export enum SegmentValue {
  WOMEN = 'women',
  MEN = 'men',
  KIDS = 'kids',
  BEAUTY = 'beauty',
  LEGACY_HOME = 'home',
  HOME_AND_LIFESTYLE = 'lifestyle',
  HOBBIES = 'hobbies',
  LUXURY = 'luxury',
  SPORTS = 'sports',
}
