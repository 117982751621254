import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { WishlistStore } from './types';

export const createWishlistStore = () => {
  return createStore(
    mutative<WishlistStore>((set) => {
      return {
        // Observables
        isSubmitting: false,

        // Actions
        setIsSubmitting: (isSubmitting) => {
          set((state) => {
            state.isSubmitting = isSubmitting;
          });
        },
      };
    }),
  );
};
