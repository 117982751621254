//https://www.sciencedirect.com/science/article/abs/pii/S0749596X19300786

/**
 * Averagely, people read with 183 WPM
 * @param message
 * @returns duration time of Toast
 */

export const getDurationTimeOfToast = (message: string) => {
  const numberOfWords = message.trim().split(' ').length;

  return Math.max(numberOfWords * 330, 2000);
};
