import { AppProps } from 'next/app';
import { ComponentType, useMemo } from 'react';
import { SWRConfig } from 'swr';

type PageProps = {
  fallback?: Record<string, unknown>;
};

const withSWRConfig = <Props extends AppProps<PageProps> = AppProps>(
  Component: ComponentType<Props>,
) => {
  const SWRConfigWrapper = (props: Props) => {
    const fallback = useMemo(() => {
      if (props.pageProps && 'fallback' in props.pageProps && props.pageProps.fallback) {
        return props.pageProps.fallback;
      }

      return {};
    }, [props.pageProps]);

    return (
      <SWRConfig value={{ fallback }}>
        <Component {...props} />
      </SWRConfig>
    );
  };

  return SWRConfigWrapper;
};

export default withSWRConfig;
