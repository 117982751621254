import { defaultLangByCountry, i18nRegionalLanguages, i18nLanguages } from 'i18n/config';
import { getEnvConfiguration } from 'utils/env-configuration';
import DOMAINS from '../constants/domains';

export type LanguageCode = keyof typeof i18nRegionalLanguages;

const DOMAINS_LOCALE_MAPPING = {
  [getEnvConfiguration('ZALORA_DOMAIN_EN_HK') as string]: i18nRegionalLanguages['en-HK'],
  [getEnvConfiguration('ZALORA_DOMAIN_ZH_HK') as string]: i18nRegionalLanguages['zh-HK'],
  [getEnvConfiguration('ZALORA_DOMAIN_ZH_TW') as string]: i18nRegionalLanguages['zh-TW'],
  [getEnvConfiguration('ZALORA_DOMAIN_EN_PH') as string]: i18nRegionalLanguages['en-PH'],
  [getEnvConfiguration('ZALORA_DOMAIN_ID_ID') as string]: i18nRegionalLanguages['id-ID'],
  [getEnvConfiguration('ZALORA_DOMAIN_EN_MY') as string]: i18nRegionalLanguages['en-MY'],
  [getEnvConfiguration('ZALORA_DOMAIN_EN_SG') as string]: i18nRegionalLanguages['en-SG'],
};

/**
 * @returns A "Zalora" domain (see `domains.js`) without its subdomain. If a
 * domain without subdomain is passed it will return the passed `hostname`.
 */
export const getZaloraDomainWithoutSubdomain = (hostname: string) => {
  const regionalLanguages = ['www', ...Object.keys(DOMAINS)];

  for (let i = 0; i < regionalLanguages.length; i++) {
    const regionalLanguage = regionalLanguages[i];
    const [potentialSubdomain] = regionalLanguage.split('-');
    const regexp = new RegExp(`^${potentialSubdomain}\.`);

    if (regexp.test(hostname)) {
      return hostname.replace(regexp, '');
    }
  }

  return hostname;
};

export const getLocaleByDomain = (hostname: string): string => {
  const result = Object.entries(DOMAINS_LOCALE_MAPPING).find(([domain]) => domain === hostname);

  if (result && result.length > 0) {
    // the result will be [{domain}, {locale}] because of Object.entries
    return result[1] as string;
  }

  const currentCountry = getCurrentCountry();
  const defaultLang = getDefaultLanguage(currentCountry);

  return `${defaultLang}-${currentCountry}`;
};

export const getStaticAsset = (assetPath: string, isNextAsset = true) => {
  const staticAssetDomain = getEnvConfiguration('STATIC_ASSET_DOMAIN') || '';
  const cleanedAssetPath = assetPath.startsWith('/') ? assetPath.slice(1) : assetPath;

  // if it's not a next asset, the asset is under our CDN domain directly.
  if (!isNextAsset) {
    return `${staticAssetDomain}/${cleanedAssetPath}`;
  }

  // this case is for DEV mode where STATIC_ASSET_DOMAIN is not set
  if (!staticAssetDomain) {
    return `/${cleanedAssetPath}`;
  }

  // `next-assets` is the root folder of assets on S3. It's mandatory to have it in the path.
  // `/{asset}` has the same path in `public/static-assets` folder.
  return `${staticAssetDomain}/next-assets/${cleanedAssetPath}`;
};

export const getLanguageByDomain = (hostname: string): string => {
  const chunks = hostname.split('.');

  if (chunks[0].includes(i18nLanguages.ZH)) {
    return i18nLanguages.ZH;
  }

  const currentCountry = getCurrentCountry();

  return getDefaultLanguage(currentCountry);
};

export const getDomainByLocale = (locale: string): string => {
  const result = Object.entries(DOMAINS_LOCALE_MAPPING).find(([, lc]) => locale === lc);
  const domain = result ? result[0] : '';

  return domain ? `https://${domain}` : '';
};

type CountryCode = keyof typeof defaultLangByCountry;

const getDefaultLanguage = (countryCode: CountryCode): string => {
  const defaultLang = defaultLangByCountry[countryCode];

  return defaultLang;
};

const getCurrentCountry = () => {
  return getEnvConfiguration('CC')?.toUpperCase() as CountryCode;
};
