import { ZDTCart } from '@zalora/doraemon-ts';
import { PromotionListItem } from 'types/api/cart/PromotionPayload';

/**
 * Map structured promotions to skuCoupons string used for item-level promotion
 * Format: skuCoupons=sku1:promoID1|promoID2,sku2:promoID1|promoID2
 */
export const mapToSkuCoupons = (groups: ZDTCart.GroupedCartItems[] | null) => {
  if (!groups) {
    return '';
  }

  return groups.reduce((prev: string, cartItemGroup) => {
    const cartItems = cartItemGroup.CartItems || [];

    // skuPromos = ['sku1:promo1|promo2', 'sku2:promo']
    const skuPromos = cartItems
      .filter(({ PromoAppliedDetail }) => !!PromoAppliedDetail)
      .map(
        ({ SimpleSku, PromoAppliedDetail }) =>
          `${SimpleSku}:${flattenPromoObject(
            PromoAppliedDetail as NonNullable<typeof PromoAppliedDetail>,
          ).join('|')}`,
      )
      .join();

    return prev ? `${prev},${skuPromos}` : skuPromos;
  }, '');
};

/**
 * Map structured promotions to promotionList array used for cart-level promotion
 * This has ids param which supports dynamic use cases
 */
export const mapToPromotionList = (
  section: ZDTCart.CartPromoSection | null | undefined,
  ids: string[],
): PromotionListItem[] => {
  if (!section) {
    return [];
  }

  const promosById = flattenPromoObject(section).reduce(
    (prev, promo) => ({
      ...prev,
      [promo.PromotionIdentifier as string]: promo,
    }),
    {} as Record<string, ZDTCart.AvailableVoucher>,
  );

  return ids.map((id) => {
    const promo = promosById[id];

    if (!promo) {
      return { promotion_identifier: id };
    }

    return {
      promotion_identifier: promo.PromotionIdentifier,
      promotion_type: promo.PromotionType,
      name: promo.Code,
    };
  });
};

export const refineManualVCs = (
  vcs: ZDTCart.Cart['ManualVcs'],
  appliedPromos: PromotionListItem[] = [],
) => {
  if (!vcs || vcs.length <= 0) {
    return '';
  }

  const appliedManualVCs = appliedPromos
    .filter((p) => vcs.includes(p.name ?? ''))
    .map((p) => p.name);

  return appliedManualVCs.filter(Boolean).join();
};

type Section = ZDTCart.CartPromoSection | ZDTCart.PromoSection;
type Details = ZDTCart.CartPromoAppliedDetail | ZDTCart.PromoAppliedDetail;
type PromoList<T> = T extends Section ? ZDTCart.AvailableVoucher[] : string[];

export const flattenPromoObject = <T extends Section | Details>(obj: T): PromoList<T> =>
  Object.values(obj).filter(Boolean).flat();
