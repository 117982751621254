import { init as initApm } from '@elastic/apm-rum';
import { getEnvConfiguration } from './env-configuration';
import isServer from './is-server';
import { captureError } from './raven';

export const initElasticRum = () => {
  const serverUrl = getEnvConfiguration('ELASTIC_RUM_SERVER_URL');
  const sampleRate = getEnvConfiguration('ELASTIC_RUM_TRANSACTION_SAMPLE_RATE');

  if (isServer() || !serverUrl || !sampleRate) {
    return;
  }

  try {
    initApm({
      serviceName: getEnvConfiguration('ELASTIC_APM_SERVICE_NAME'),
      serverUrl,
      environment: getEnvConfiguration('CC'),
      transactionSampleRate: +sampleRate,
      disableInstrumentations: ['error'],
    });
  } catch (error) {
    captureError("Can not init Elastic APM's RUM", { error, tag: 'handler' });
  }
};
