export const DEFAULT_ENABLE_VARIATION = 'true';
export const FALSE = 'false';
export const NOT_APPLICABLE = 'not_applicable';

export const ENABLED_PRICE_DISPLAY = 'web_enable_price_display';

export const OPTIMIZELY_VARIANT = 'variant';

export const VARIANT_A = 'variant_a';
export const VARIANT_B = 'variant_b';
