// @ts-check

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { i18nRegionalLanguages } = require('../i18n/config');

/** @type {import('./domains').DomainConfiguration} */
const DOMAINS = {
  [i18nRegionalLanguages['en-HK']]: process.env.ZALORA_DOMAIN_EN_HK || 'www.zalocal.com.hk',
  [i18nRegionalLanguages['zh-HK']]: process.env.ZALORA_DOMAIN_ZH_HK || 'zh.zalocal.com.hk',
  [i18nRegionalLanguages['id-ID']]: process.env.ZALORA_DOMAIN_ID_ID || 'www.zalocal.co.id',
  [i18nRegionalLanguages['en-PH']]: process.env.ZALORA_DOMAIN_EN_PH || 'www.zalocal.com.ph',
  [i18nRegionalLanguages['en-SG']]: process.env.ZALORA_DOMAIN_EN_SG || 'www.zalocal.sg',
  [i18nRegionalLanguages['zh-TW']]: process.env.ZALORA_DOMAIN_ZH_TW || 'www.zalocal.com.tw',
  [i18nRegionalLanguages['en-MY']]: process.env.ZALORA_DOMAIN_EN_MY || 'www.zalocal.com.my',
};

module.exports = DOMAINS;
