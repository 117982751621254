import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { CatalogStore } from './types';

export const createCatalogStore = () => {
  return createStore(
    mutative<CatalogStore>((set) => ({
      // Observables
      shouldShowVariantTooltip: false,
      skuFirstProductHasVariations: '',
      socialProofing: {},

      // Actions
      showVariantTooltip: (shouldShowVariantTooltip) => {
        set((state) => {
          state.shouldShowVariantTooltip = shouldShowVariantTooltip;
        });
      },

      setSkuFirstProductHasVariations: (sku) => {
        set((state) => {
          state.skuFirstProductHasVariations = sku;
        });
      },
    })),
  );
};
