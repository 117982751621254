import { AppProps } from 'next/app';
import { ComponentType, createContext, useMemo } from 'react';
import { StoreApi } from 'zustand';
import { createCartStore } from 'stores/cart/store';
import { CartStore } from 'stores/cart/types';
import { createCatalogStore } from 'stores/catalog/store';
import { CatalogStore } from 'stores/catalog/types';
import { createDeliveryStore } from 'stores/delivery/store';
import { DeliveryStore } from 'stores/delivery/types';
import { createLandingPageStore } from 'stores/landingPage/store';
import { LandingPageStore } from 'stores/landingPage/types';
import { createOptimizelyStore } from 'stores/optimizely/store';
import { OptimizelyStore } from 'stores/optimizely/types';
import { createPdvStore } from 'stores/pdv/store';
import { PdvStore } from 'stores/pdv/types';
import { createUiStore } from 'stores/ui/store';
import { UiStore } from 'stores/ui/types';
import { createWishlistStore } from 'stores/wishlist/store';
import { WishlistStore } from 'stores/wishlist/types';

const createStoreFnMap = {
  cart: createCartStore,
  catalog: createCatalogStore,
  pdv: createPdvStore,
  landingPage: createLandingPageStore,
  ui: createUiStore,
  wishlist: createWishlistStore,
  optimizely: createOptimizelyStore,
  delivery: createDeliveryStore,
};

export interface ContextValue {
  cart?: StoreApi<CartStore>;
  catalog?: StoreApi<CatalogStore>;
  pdv?: StoreApi<PdvStore>;
  landingPage?: StoreApi<LandingPageStore>;
  ui?: StoreApi<UiStore>;
  wishlist?: StoreApi<WishlistStore>;
  optimizely?: StoreApi<OptimizelyStore>;
  delivery?: StoreApi<DeliveryStore>;
}

export const StoreContext = createContext<ContextValue>({} as never);

export const withStoreProvider = <Props extends AppProps>(Component: ComponentType<Props>) => {
  const AppContextWrapper = (props: Props) => {
    const { pageProps } = props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { preloadedState = {} } = pageProps as any;

    const store = useMemo(() => {
      return Object.keys(preloadedState).reduce((stores: ContextValue, storeName) => {
        const createStore = createStoreFnMap[storeName as keyof typeof createStoreFnMap];

        return {
          ...stores,
          [storeName]: createStore(preloadedState[storeName]),
        };
      }, {});
    }, [preloadedState]);

    if (!store) {
      return null;
    }

    return (
      <StoreContext.Provider value={store}>
        <Component {...props} />
      </StoreContext.Provider>
    );
  };

  return AppContextWrapper;
};
