import isServer from './is-server';

/**
 * The x characters represent random hexadecimal digits,
 * while the y character represents a random hexadecimal digit from the set [8, 9, A, or B],
 * as required by the UUIDv4 format.
 */
const UUID_V4_TEMPLATE = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

export const randomUUID = async (): Promise<string> => {
  if (isServer()) {
    return import('crypto').then(({ randomUUID }) => randomUUID());
  }

  return randomUUIDOnClient();
};

export const randomUUIDOnClient = () => {
  return UUID_V4_TEMPLATE.replace(/[xy]/g, (char) => {
    const randomHexDigit = (Math.random() * 16) | 0;
    const replacement = char === 'x' ? randomHexDigit : (randomHexDigit & 0x3) | 0x8;

    return replacement.toString(16);
  });
};
