import Cookie, { getExpireTime } from './cookie';
import { randomUUIDOnClient } from './randomUUID';

const ANONYMOUS_ID_COOKIE_KEY = 'ANONYMOUS_TRACKING_ID';
const ANONYMOUS_ID_SEPARATOR = '|';

/**
 * Lotus is follow Cacheability principle,
 * So, we don't use anonymousId from server side
 */

export const generateAnonymousId = () => {
  const cookie = new Cookie();

  const anonymousId = createAnonymousId();
  const expireTime = getExpireTime({ years: 2 });

  cookie.set(ANONYMOUS_ID_COOKIE_KEY, anonymousId, expireTime.toUTCString());

  return anonymousId;
};

export const getAnonymousId = (): string => {
  const cookie = new Cookie();
  const anonymousIdCookieStr = cookie.get(ANONYMOUS_ID_COOKIE_KEY);
  const anonymousId = getAnonymousIdFromCookieStr(anonymousIdCookieStr);

  return anonymousId;
};

const getAnonymousIdFromCookieStr = (anonymousIdCookieStr?: string): string => {
  if (!anonymousIdCookieStr) {
    return '';
  }

  // In Alita, we have a cookie like this: "anonymousId|userId"
  // So, we need to split it and get the first part
  return anonymousIdCookieStr.split(ANONYMOUS_ID_SEPARATOR)[0];
};

const createAnonymousId = () => {
  const anonymousId = randomUUIDOnClient();

  return anonymousId;
};
