import { createClient } from '@zalora/doraemon-ts/lib/client';
import { getLocaleByDomain } from 'utils/domains';
import { suffixUserAgent } from 'utils/e2e';
import { getEnvConfiguration } from 'utils/env-configuration';
import isServer from 'utils/is-server';

const doraemon = (() => {
  const revision = getEnvConfiguration('REVISION') || 'dev-local';

  const endpoint = isServer()
    ? process.env.DOR_API_HOST_INTERNAL
    : getEnvConfiguration('DOR_API_HOST_EXTERNAL');

  const getServerUserAgent = () => {
    let serverUserAgent = `zalora/lotus-${revision}`;

    if (process.env.IS_PLAYWRIGHT === 'true') {
      serverUserAgent = suffixUserAgent(serverUserAgent);
    }

    return serverUserAgent;
  };

  return createClient({
    baseUrl: `${endpoint}/v1`,
    defaultTimeout: 30_000,
    defaultHeaders: {
      Accept: 'application/json',
      ...(isServer()
        ? {
            'User-Agent': getServerUserAgent(),

            /**
             * On server-side, content-language will be attached from next page
             * lifecycle: getServerSideProps, getStaticProps If not, DOR will
             * decide fallback, we don't need to send default locale.
             */
          }
        : {
            // On client-side, Content-Language will be detected from url (hostname)
            'Content-Language': getLocaleByDomain(location.hostname),
          }),
    },
  });
})();

export default doraemon;
