import { useRouter } from 'next/router';
import { FF_CONFIG_BY_ROUTE } from 'constants/bob-feature-flags';
import FeatureFlags from 'constants/feature-flags';
import { BobFeatureFlagConfigKey } from 'types/bob-feature-flags';
import { isBobFeatureFlagEnabled, isServerSideUsedFeatureFlag } from 'utils/bob-feature-flags';
import { getRouteKeyByAsPath } from 'utils/router';
import { useBobFeatureFlags } from './useBobFeatureFlags';

export const useEnabledBobFeatureFlag = (
  flag: FeatureFlags.BobFlag,
  pageRoute?: BobFeatureFlagConfigKey,
) => {
  const router = useRouter();
  const routeKey = pageRoute ? pageRoute : getRouteKeyByAsPath(router.asPath);

  const isServerSideUsed = isServerSideUsedFeatureFlag({
    routeKey,
    featureFlagConfig: FF_CONFIG_BY_ROUTE,
    flagOrFlags: flag,
  });

  const { data, isLoading } = useBobFeatureFlags();

  if ((!isServerSideUsed && isLoading) || !data) {
    return undefined;
  }

  return isBobFeatureFlagEnabled(data, flag);
};
