/**
 * ⚠️ Regular E2E utils should be in `e2e/utils`, however, if code is shared
 * between Lotus and Playwright tests it can be placed here (as the `e2e`)
 * folder is invisible to Jest and will result in errors).
 */

const QA_USER_AGENT_SUFFIX = 'SHOPQALotusAutomation';

export const suffixUserAgent = (userAgent: string) => {
  return `${userAgent} ${QA_USER_AGENT_SUFFIX}`;
};
