import { SegmentValue } from 'types/Segment';

export const EXPLORE = 'explore';
export const FOR_YOU = 'For You';
const { WOMEN, MEN, SPORTS, LUXURY, BEAUTY, KIDS, HOME_AND_LIFESTYLE, HOBBIES, LEGACY_HOME } =
  SegmentValue;

export const SEGMENTS_LIST = {
  sg: [EXPLORE, WOMEN, MEN, LUXURY, SPORTS, BEAUTY, KIDS, HOME_AND_LIFESTYLE, HOBBIES],
  my: [EXPLORE, WOMEN, MEN, LUXURY, SPORTS, BEAUTY, KIDS, HOME_AND_LIFESTYLE, HOBBIES],
  tw: [EXPLORE, WOMEN, MEN, LUXURY, SPORTS, BEAUTY, KIDS, HOME_AND_LIFESTYLE, HOBBIES],
  hk: [EXPLORE, WOMEN, MEN, LUXURY, SPORTS, BEAUTY, KIDS, HOME_AND_LIFESTYLE, HOBBIES],
  id: [EXPLORE, WOMEN, MEN, SPORTS, KIDS, LUXURY, BEAUTY, HOME_AND_LIFESTYLE, HOBBIES],
  ph: [EXPLORE, WOMEN, MEN, LUXURY, SPORTS, KIDS, BEAUTY, HOME_AND_LIFESTYLE, HOBBIES, LEGACY_HOME],
};

export const FILTER_ALL_SEGMENT_OPTION = 'all';
