import { ZDTCustomer, ZDTNewsletter } from '@zalora/doraemon-ts';
import { SESSION_STORAGE_KEYS } from 'constants/session-storage';
import { getEnvConfiguration } from 'utils/env-configuration';
import { isUserSubscribedNewsLetter } from 'utils/user/news-letter/isUserSubscribedNewsLetter';
import { isObject } from 'utils/validation';
import { getPlatformSource } from './get-platform-source';
import { getUserTraits, shouldAllowTracking } from './methods';

export const SEGMENT_LOGIN_METHOD_COOKIE_KEY = 'segment-login-method';

type LoginMethod = 'signIn' | 'signUp';

const LoginTrackingEventName: Record<LoginMethod, string> = {
  signIn: 'Signed In',
  signUp: 'Signed Up',
};

export const identify = (userId: string, traits?: unknown) => {
  if (!userId || !shouldAllowTracking()) {
    return;
  }

  window.analytics.identify(userId, isObject(traits) ? traits : {});
};

export const identifyUser = ({
  user,
  newsLetter,
}: {
  user: ZDTCustomer.Customer;
  newsLetter: Nullishable<ZDTNewsletter.Details[]>;
}) => {
  if (!user.Zuid) {
    return;
  }

  const venture = getEnvConfiguration('CC');
  const emailSubscribed = isUserSubscribedNewsLetter(newsLetter);

  identify(
    user.Zuid,
    getUserTraits({
      user,
      venture,
      emailSubscribed,
    }),
  );
};

/**
 * This is supporting for during transition phase, where we do login in alita.
 * After Alita logins successful on server PHP, Alita will set a login method (segment-login-method) to cookie
 * So, from Lotus, we will check if segment-login-method is available in cookie, then we have to call identify
 */
export const identifyAfterLogin = (
  loginMethod: string,
  user: ZDTCustomer.Customer,
  newsLetter: Nullishable<ZDTNewsletter.Details[]>,
  isInteractiveJourneyEnabled: boolean,
) => {
  if (!user.Zuid || !shouldAllowTracking()) {
    return;
  }

  const venture = getEnvConfiguration('CC');
  const emailSubscribed = isUserSubscribedNewsLetter(newsLetter);

  identifyUser({ user, newsLetter });

  window.analytics.track(
    LoginTrackingEventName[loginMethod as LoginMethod],
    generatePayloadForInteractiveJourneyTracking(
      {
        user_id: user.Zuid,
        email: user.Email,
        platform_source: getPlatformSource(),
      },
      isInteractiveJourneyEnabled,
    ),
    {
      /**
       * We explicitly set traits to avoid a race condition between this event,
       * and the point where we're adding traits to the payload in
       * addSourceMiddleware
       */
      context: {
        traits: getUserTraits({ user, venture, emailSubscribed }),
      },
    },
  );
};

export const generatePayloadForInteractiveJourneyTracking = <T extends Record<string, unknown>>(
  payload: T,
  isInteractiveJourneyEnabled: boolean,
): T | (T & { list_id?: string; nc_interactive_variant?: string }) => {
  const key = SESSION_STORAGE_KEYS.INTERACTIVE_JOURNEY_ORIGIN_STEP;
  const origin = sessionStorage.getItem(key);

  Object.assign(payload, {
    nc_interactive_variant: isInteractiveJourneyEnabled ? 'nc_interactive_journey' : 'false',
    list_id: isInteractiveJourneyEnabled ? 'interactive_journey' : undefined,
  });

  if (!origin) {
    return payload;
  }

  sessionStorage.removeItem(key);

  return payload;
};
