import Cookie from 'utils/cookie';
import { SEGMENT_LOGIN_METHOD_COOKIE_KEY } from 'utils/segment-analytic';

export const getLoginMethod = () => {
  const cookie = new Cookie();

  return cookie.get(SEGMENT_LOGIN_METHOD_COOKIE_KEY);
};

export const removeLoginMethod = () => {
  const cookie = new Cookie();

  cookie.remove(SEGMENT_LOGIN_METHOD_COOKIE_KEY);
};
