import { getTrackPageCallTitle } from 'utils/next';
import { getPlatformSource } from './get-platform-source';
import { getCipImpactUrl, getOptions, shouldAllowTracking } from './index';

export const trackPageCall = () => {
  if (!shouldAllowTracking()) {
    return;
  }

  const platformSource = getPlatformSource();
  const options = getOptions();

  window.analytics.page(
    {
      platform_source: platformSource,
      cip_impact_url: getCipImpactUrl(window.location.href),
      title: getTrackPageCallTitle(),
    },
    options,
  );
};
