import { ZDTCustomer } from '@zalora/doraemon-ts';
import { useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';
import doraemon from 'api/doraemon';
import { getEnvConfiguration } from 'utils/env-configuration';
import { shouldAllowTracking } from 'utils/segment-analytic/methods';

const useUser = () => {
  const loadSegmentAnalytics = useCallback((user: ZDTCustomer.Customer | null | undefined) => {
    // this is a hack for segment tracking.
    // for every segment tracking event, we have to attach userTraits
    // so, we have to publish user data to window object
    window.__USER__ = user;

    if (!shouldAllowTracking() || !('load' in window.analytics)) {
      return;
    }

    const segmentApiKey = getEnvConfiguration('SEGMENT_API_KEY');

    if (!segmentApiKey) {
      return;
    }

    // same as above, addSourceMiddleware is a valid method
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/middleware/
    // we need this middleware to attach user traits to every event
    // @ts-ignore
    window.analytics.addSourceMiddleware(({ payload, next }) => {
      try {
        const traits = payload.obj.context.traits || {};

        if (!!window.__USER__ && Object.keys(traits).length === 0) {
          payload.obj.context.traits = window.analytics.user().traits();
        }
      } catch (e) {
        // do nothing
      }

      next(payload);
    });

    // as @types/segment-analytics@0.0.34, SegmentOpts does not support flushAt
    // however, as confirmed from segment team, flushAt is a valid option
    analytics.load(segmentApiKey, { flushAt: 1 } as SegmentAnalytics.SegmentOpts & {
      flushAt: number;
    });
  }, []);

  const response = useSWRImmutable(
    '/v1/customers',
    async () => {
      const { data: user } = await doraemon.request('GET /customers/', {
        request: { credentials: 'include' },
      });

      return user;
    },
    {
      dedupingInterval: 60000,
      shouldRetryOnError: false,
      // if this option is false, `isLoading` will always be true
      revalidateOnMount: true,
      fallbackData: null,

      onSuccess: (user) => {
        loadSegmentAnalytics(user);
      },

      onError: () => {
        loadSegmentAnalytics(null);
      },
    },
  );

  return response;
};

export default useUser;
