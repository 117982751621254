import FeatureFlags from 'constants/feature-flags';
import { ZDTFeatureFlag } from 'types/ZDT';
import { ApiResponse } from 'types/api/ApiResponse';
import { authFetch } from './APIClient';
import { buildQueryString } from './util';

export const getEnabledFeatureFlags = async (flags: FeatureFlags.BobFlag[]) => {
  const targetUrl = `/v1/featureflag?${buildQueryString({ flag: flags })}`;

  return await authFetch<ApiResponse<ZDTFeatureFlag.FeatureFlag>>(targetUrl);
};
