// @ts-check

/** @type {import('./screen-size')} */
const SCREEN_SIZES = {
  SMOBILE: {
    name: 'SMOBILE',
    minPixels: 0,
    layoutType: 'mWeb',
  },
  LMOBILE: {
    name: 'LMOBILE',
    minPixels: 375,
    layoutType: 'mWeb',
  },
  TABLET: {
    name: 'TABLET',
    minPixels: 744,
    layoutType: 'mWeb',
  },
  DESKTOP: {
    name: 'DESKTOP',
    minPixels: 1240,
    layoutType: 'Web',
  },
};

module.exports = SCREEN_SIZES;
