import { useRouter } from 'next/router';
import { useMemo } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { FF_CONFIG_BY_ROUTE } from 'constants/bob-feature-flags';
import { fetchBobFeatureFlags, getSwrKeyByRoute } from 'utils/bob-feature-flags';
import { getRouteKeyByAsPath } from 'utils/router';

export const useBobFeatureFlags = () => {
  const router = useRouter();
  const routeKey = getRouteKeyByAsPath(router.asPath);

  const { server: serverSwrKey, client: clientSwrKey } = useMemo(
    () => getSwrKeyByRoute(routeKey, FF_CONFIG_BY_ROUTE),
    [routeKey],
  );

  // Most the case, this will be populated by the server by fallback props
  const { data: serverData } = useSWRImmutable(serverSwrKey, () =>
    fetchBobFeatureFlags({
      routeKey,
      featureFlagConfig: FF_CONFIG_BY_ROUTE,
      options: {
        isServerOnly: true,
      },
    }),
  );

  const { data: clientData, isLoading } = useSWR(
    clientSwrKey,
    () =>
      fetchBobFeatureFlags({
        routeKey,
        featureFlagConfig: FF_CONFIG_BY_ROUTE,
        options: {
          isClientOnly: true,
        },
      }),
    {
      dedupingInterval: 5 * 60 * 1000, // 5 mins
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    },
  );

  return { data: [...(serverData || []), ...(clientData || [])], isLoading };
};
