import { ZDTNewsletter } from '@zalora/doraemon-ts';

const enum NewsLetterType {
  Trends = 'trends',
  Whatsapp = 'whatsapp',
}

export const isUserSubscribedNewsLetter = (newsLetter: Nullishable<ZDTNewsletter.Details[]>) => {
  if (!newsLetter) {
    return false;
  }

  return newsLetter.some((item) => item.Name === NewsLetterType.Trends && item.Subscribed);
};
