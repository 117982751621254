import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { PdvStore } from './types';

type PreloadedState = Pick<PdvStore, 'product' | 'reviewStatistics' | 'specs' | 'care'>;

export const createPdvStore = (preloadedState: PreloadedState) => {
  return createStore(
    mutative<PdvStore>((set) => ({
      // Observables
      product: preloadedState.product,
      reviewStatistics: preloadedState.reviewStatistics,
      specs: preloadedState.specs,
      care: preloadedState.care,
      selectedSizeData: { size: undefined, system: undefined },
      sizeErrorMessage: undefined,
      toastStack: [],
      onSizeSelected: undefined,
      shouldShowSizeGuideOnboarding: false,
      socialProofing: {},
      outOfStockContext: undefined,

      // Actions
      setSelectedSizeData: (selectedSizeData) => {
        set((state) => {
          state.selectedSizeData = selectedSizeData;
          state.outOfStockContext = undefined;
        });
      },

      setSizeErrorMessage: (sizeErrorMessage) => {
        set((state) => {
          state.sizeErrorMessage = sizeErrorMessage;
        });
      },

      setProduct: (product) => {
        set((state) => {
          state.product = product;
        });
      },

      showSizeGuideOnboarding: (shouldShowSizeGuideOnboarding) => {
        set((state) => {
          state.shouldShowSizeGuideOnboarding = shouldShowSizeGuideOnboarding;
        });
      },

      setOutOfStockContext: (outOfStockContext) => {
        set((state) => {
          state.selectedSizeData = { ...state.selectedSizeData, size: undefined };
          state.outOfStockContext = outOfStockContext;
        });
      },
    })),
  );
};
