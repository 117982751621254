// @ts-check

/**
 * @type {import('./config')['DEFAULT_LANGUAGE']}
 */
const DEFAULT_LANGUAGE = 'en';

/**
 * @type {import('./config')['DEFAULT_NAMESPACE']}
 */
const DEFAULT_NAMESPACE = 'common';

/**
 * @type {import('./config')['i18nLanguages']}
 */
const i18nLanguages = Object.freeze({
  EN: 'en',
  ID: 'id',
  ZH: 'zh',
});

/**
 * @type {import('./config')['i18nRegionalLanguages']}
 */
const i18nRegionalLanguages = Object.freeze({
  'en-HK': 'en-HK',
  'en-PH': 'en-PH',
  'en-SG': 'en-SG',
  'en-MY': 'en-MY',
  'id-ID': 'id-ID',
  'zh-HK': 'zh-HK',
  'zh-TW': 'zh-TW',
});

/**
 * @type {import('./config')['defaultLangByCountry']}
 */
const defaultLangByCountry = Object.freeze({
  PH: 'en',
  SG: 'en',
  MY: 'en',
  ID: 'id',
  HK: 'en',
  TW: 'zh',
});

/**
 * @type {import('./config')['i18nNamespaces']}
 */
const i18nNamespaces = Object.freeze({
  COMMON: 'common',
  SEGMENT: 'segment',
  FOOTER: 'footer',
  CATALOG: 'catalog',
  CART: 'cart',
  ACCOUNT: 'account',
  ORDER: 'order',
  PDV: 'pdv',
  PDV_NOT_FOUND: 'pdv-not-found',
  WISHLIST: 'wishlist',
  INTERACTIVE_JOURNEY: 'interactive-journey',
  EINVOICE: 'e-invoice',
  PRICE_DROP: 'price-drop',
});

module.exports = {
  DEFAULT_LANGUAGE,
  DEFAULT_NAMESPACE,
  i18nLanguages,
  i18nNamespaces,
  i18nRegionalLanguages,
  defaultLangByCountry,
};
