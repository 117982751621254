import { Env } from 'constants/env-configuration';
import isServer from './is-server';

export interface EnvConfiguration {
  /**
   * The environment name: dev | staging | production
   */
  ENV?: Env;
  /**
   * Country code: sg | id | my | ph | hk | tw ...
   */
  CC?: string;
  /**
   * * DSN key for Sentry host
   */
  SENTRY_DSN?: string;
  SENTRY_ENV?: string;
  /**
   * The key for Segment
   */
  SEGMENT_API_KEY?: string;
  /**
   * The space id for Segment
   */
  SEGMENT_SPACE_ID?: string;
  /**
   * The key for Client Optimizely
   */
  OPTIMIZELY_CLIENT_API_KEY?: string;
  /**
   * The key for Server Optimizely
   */
  OPTIMIZELY_API_KEY?: string;
  /**
   * The key for Optimizely Token
   */
  OPTIMIZELY_API_TOKEN?: string;
  /**
   * Set this property to the desired logging level.
   *
   * https://github.com/pinojs/pino/blob/master/docs/api.md#logger-level
   */
  LOG_LEVEL?: string;
  /**
   * The BASE_URL for Server
   */
  DOR_API_HOST_INTERNAL?: string;
  /**
   * The BASE_URL for Client
   */
  DOR_API_HOST_EXTERNAL?: string;

  // Domains
  ZALORA_DOMAIN_EN_HK?: string;
  ZALORA_DOMAIN_ZH_HK?: string;
  ZALORA_DOMAIN_ID_ID?: string;
  ZALORA_DOMAIN_EN_PH?: string;
  ZALORA_DOMAIN_EN_SG?: string;
  ZALORA_DOMAIN_ZH_TW?: string;
  ZALORA_DOMAIN_EN_MY?: string;

  // Universal links
  ANDROID_PACKAGE_ID?: string;
  IOS_APP_ID?: string;

  // GTM
  GTM_KEY?: string;

  // Citrus Tracking
  CITRUS_BASE_URL?: string;
  CITRUS_SDK_URL?: string;

  // Contentful
  CONTENTFUL_DELIVERY_API_ACCESS_TOKEN?: string;
  CONTENTFUL_PREVIEW_API_ACCESS_TOKEN?: string;
  CONTENTFUL_ENVIRONMENT?: string;
  CONTENTFUL_SPACE_ID?: string;
  // Revision
  REVISION?: string;

  // Static asset domain
  STATIC_ASSET_DOMAIN?: string;

  // RUM
  ELASTIC_RUM_SERVER_URL?: string;
  ELASTIC_RUM_TRANSACTION_SAMPLE_RATE?: string;
  ELASTIC_APM_SERVICE_NAME?: string;

  // MAP
  GOOGLE_MAP_KEY?: string;
  GOOGLE_MAP_ID?: string;

  // GOOGLE SITE VERIFICATION
  GOOGLE_SITE_VERIFICATION_KEY?: string;

  // PARTYTOWN
  ENABLE_PARTYTOWN?: 'true';

  //HUMAN SCRIPT
  HUMAN_KEY?: string;

  // LINE ADS
  LINE_ADS_ID?: string;

  // APPSFLYER
  APPSFLYER_ONE_LINK_URL?: string;

  // MIXPANEL
  MIXPANEL_TOKEN?: string;
}

export type EnvKey = `${keyof EnvConfiguration}`;

/**
 * Get configuration from server
 */
export const getEnvConfiguration = (key: EnvKey): string | undefined => {
  if (isServer()) {
    return process.env[key];
  }

  const env = window.__ENV__ || {};

  return env[key];
};
