interface ApiErrorType {
  url: string;
  message: string;
  type?: string;
  code?: string; // error code defined by the API
  status?: number; // HTTP status code
  data?: unknown; // additional data
}

export class ApiError extends Error implements ApiErrorType {
  url: string;
  message: string;
  type?: string;
  code?: string;
  status?: number;
  data?: unknown;

  constructor({ message, code, status, data, url, type }: ApiErrorType) {
    super(message);
    this.name = 'ApiError';
    this.url = url;
    this.message = message;
    this.type = type;
    this.code = code;
    this.status = status;
    this.data = data;
  }
}
