import SCREEN_SIZES from 'constants/screen-size';
import {
  DesktopScreenSize,
  MobileScreenSize,
  ScreenSize,
  TabletScreenSize,
} from 'types/ScreenSize';
import isServer from './is-server';

let screenSizeWidth = 0;
let screenSizeName: ScreenSize['name'] | undefined = undefined;
let listenerDebounce: number | undefined = undefined;

const screenSizeListeners: ((screenSize: ScreenSize) => void)[] = [];

export const registerScreenSizeObserver = () => {
  if (isServer()) {
    throw new Error('getScreenSize should only be executed on the client-side.');
  }

  const observer = new ResizeObserver(([entry]) => {
    if (screenSizeWidth !== entry.contentRect.width) {
      screenSizeWidth = entry.contentRect.width;

      window.clearTimeout(listenerDebounce);

      // Fire change in screen size to listeners
      listenerDebounce = window.setTimeout(() => {
        const screenSize = getScreenSize();

        if (screenSizeName !== screenSize.name) {
          screenSizeName = screenSize.name;

          screenSizeListeners.forEach((screenSizeListener) => {
            screenSizeListener(screenSize);
          });
        }
      }, 250);
    }
  });

  observer.observe(document.documentElement);

  return () => {
    observer.disconnect();
  };
};

export const registerScreenSizeListener = (listener: (typeof screenSizeListeners)[number]) => {
  screenSizeListeners.push(listener);

  return () => {
    screenSizeListeners.splice(screenSizeListeners.indexOf(listener), 1);
  };
};

export const getScreenSizeByPixels = (pixels: number) => {
  const screenSizeEntries = Object.entries(SCREEN_SIZES).sort(
    ([, a], [, b]) => b.minPixels - a.minPixels,
  );

  const [, screenSize] =
    screenSizeEntries.find(([, screenSize]) => {
      return pixels >= screenSize.minPixels;
    }) || screenSizeEntries[0];

  return screenSize;
};

export const getScreenSize = () => {
  if (isServer()) {
    throw new Error('getScreenSize should only be executed on the client-side.');
  }

  return getScreenSizeByPixels(screenSizeWidth || window.innerWidth);
};

export const getScreenSizeName = () => {
  if (isServer()) {
    throw new Error('getScreenSize should only be executed on the client-side.');
  }

  return screenSizeName;
};

export const getLayoutType = (pixels?: number) => {
  return pixels ? getScreenSizeByPixels(pixels).layoutType : getScreenSize().layoutType;
};

export const isMobileScreenSize = (screenSize?: ScreenSize): screenSize is MobileScreenSize => {
  if (isServer()) {
    throw new Error('isMobileScreenSize should only be executed on the client-side.');
  }

  const { name } = screenSize || getScreenSize();

  const isMobile =
    [SCREEN_SIZES.SMOBILE.name, SCREEN_SIZES.LMOBILE.name].findIndex(
      (mobileScreenSizeName) => mobileScreenSizeName === name,
    ) > -1;

  return isMobile;
};

export const isTabletScreenSize = (screenSize?: ScreenSize): screenSize is TabletScreenSize => {
  if (isServer()) {
    throw new Error('isTabletScreenSize should only be executed on the client-side.');
  }

  const { name } = screenSize || getScreenSize();

  return SCREEN_SIZES.TABLET.name === name;
};

export const isDesktopScreenSize = (screenSize?: ScreenSize): screenSize is DesktopScreenSize => {
  if (isServer()) {
    throw new Error('isDesktopScreenSize should only be executed on the client-side.');
  }

  const { name } = screenSize || getScreenSize();

  return SCREEN_SIZES.DESKTOP.name === name;
};
