export const enum SESSION_STORAGE_KEYS {
  CART_AUTH_DIALOG_SHOWN = 'cart_auth_dialog_shown',
  CART_ADDRESS_DIALOG_SHOWN = 'cart_address_dialog_shown',

  INTERACTIVE_JOURNEY_WIDGET_CLOSED = 'interactive_journey_widget_closed',
  INTERACTIVE_JOURNEY_SIGN_UP = 'interactive-journey-signup',
  INTERACTIVE_JOURNEY_LOGIN_METHOD = 'interactive-journey-login-method',
  INTERACTIVE_JOURNEY_ORIGIN_STEP = 'interactive-journey-origin-step',
  INTERACTIVE_JOURNEY_TRACKED_SIGN_UP = 'interactive-journey-tracked-sign-up',
}
