import { useEffect, useRef } from 'react';

/**
 * Callback function will be called once when the condition matches. If no condition provided,
 * `isMatchingCondition` will be `true` by default and callback function will be called
 * on the first mount.
 */
export const useInvokeCallbackOnceWhen = (callbackFunc: () => void, isMatchingCondition = true) => {
  const isInvoked = useRef(false);

  useEffect(() => {
    if (!isInvoked.current && isMatchingCondition) {
      callbackFunc();

      isInvoked.current = true;
    }
  }, [isMatchingCondition, callbackFunc]);
};
