import { RouteKey, Routes } from 'constants/routes';
import isServer from './is-server';
import { isNotFound } from './next';

export enum SOURCE_PAGE_TYPE {
  Account = 'My Account',
  Cart = 'Cart',
  Catalog = 'Catalog',
  Homepage = 'Homepage',
  ProductDetails = 'PDV',
  ProductDetailsNotFound = 'new 404 page',
  Search = 'Search',
  Wishlist = 'Wishlist',
  ZIS = 'ZIS',
  Segment = 'Segment',
  PIP = 'PIP',
  Brands = 'Brands',
  MyOrders = 'My Orders',
  OrderItemDetails = 'Item Details',
}

export const createPageTypeRegex = (routeKey: RouteKey, slugPlaceholder?: string) => {
  let transformedRouteKey = routeKey.replace('/', '\\/');

  /**
    The slugPlaceholder represents a dynamic parameter within the routeKey.
    For instance, in the routeKey '/order/:slug', 'slug' acts as the placeholder.
    Below, the code replaces the slugPlaceholder with a regex pattern for dynamic parameters.
    This pattern ([^\\/\\?]+) matches any string except '/' and '?'.
    It's used to recognize dynamic parameters within the routeKey.
    For instance, '\/order\/:slug' becomes '\/order\/([^\\/\\?]+)'.
    This regex matches strings like '/order/123', or '/order/abc', excluding '/order/', '//order/abc?item=1'.
    The complete regex pattern is '^(\/order\/([^\/\?]+)[\\/\\?{1,2}](.+)?$)|(\/order\/([^\/\?]+)[\\/\\?{1,2}]?))$'.
    It matches '/order/123', '/order/abc', '/order/123?item=1', '/order/abc?item=1'.
  */
  if (slugPlaceholder) {
    const dynamicParamPattern = '([^\\/\\?]+)';

    transformedRouteKey = transformedRouteKey.replace(`:${slugPlaceholder}`, dynamicParamPattern);
  }

  return new RegExp(
    `^((${transformedRouteKey}[\\/\\?{1,2}](.+)?$)|(${transformedRouteKey}[\\/\\?{1,2}]?))$`,
  );
};

export const PAGE_TYPE_REGEX = {
  CART: createPageTypeRegex(Routes.CART),
  CATALOG_PAGE: createPageTypeRegex(Routes.CATALOG),
  HOME_PAGE: createPageTypeRegex(Routes.HOMEPAGE),
  PDV_PAGE: createPageTypeRegex(Routes.PRODUCT),
  SEARCH_PAGE: createPageTypeRegex(Routes.SEARCH),
  WISHLIST_PAGE: createPageTypeRegex(Routes.WISHLIST),
  ZIS_PAGE: createPageTypeRegex(Routes.ZIS),
  SEGMENT_PAGE: createPageTypeRegex(Routes.SEGMENT),
  ACCOUNT_PAGE: createPageTypeRegex(Routes.ACCOUNT),
  MEMBERSHIP_PAGE: createPageTypeRegex(Routes.ACCOUNT_MEMBERSHIP),
  CASHBACK_PAGE: createPageTypeRegex(Routes.ACCOUNT_CASHBACK),
  WALLET_PAGE: createPageTypeRegex(Routes.ACCOUNT_WALLET),
  PRODUCT_INDEX_PAGE: createPageTypeRegex(Routes.PIP),
  BRANDS_PAGE: createPageTypeRegex(Routes.BRANDS),
  MY_ORDERS_PAGE: createPageTypeRegex(Routes.ACCOUNT_ORDERS),
  ORDER_ITEM_DETAILS_PAGE: createPageTypeRegex(Routes.ORDER_ITEM_DETAILS, 'slug'),
} as const;

const getDefaultPathname = () => {
  return isServer() ? '' : window.location.pathname;
};

export const isCartPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.CART.test(pathname || getDefaultPathname());
};

export const isProductIndexPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.PRODUCT_INDEX_PAGE.test(pathname || getDefaultPathname());
};

export const isPDVPage = (pathname: string) => {
  return !isNotFound() && PAGE_TYPE_REGEX.PDV_PAGE.test(pathname || getDefaultPathname());
};

export const isPDVNotFoundPage = (pathname: string) => {
  return isNotFound() && PAGE_TYPE_REGEX.PDV_PAGE.test(pathname || getDefaultPathname());
};

export const isBrandsPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.BRANDS_PAGE.test(pathname || getDefaultPathname());
};

export const isSearchPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.SEARCH_PAGE.test(pathname || getDefaultPathname());
};

export const isCatalogPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.CATALOG_PAGE.test(pathname || getDefaultPathname());
};

export const isHomePage = (pathname: string) => {
  return PAGE_TYPE_REGEX.HOME_PAGE.test(pathname || getDefaultPathname());
};

export const isSegmentPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.SEGMENT_PAGE.test(pathname || getDefaultPathname());
};

export const isWishlistPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.WISHLIST_PAGE.test(pathname || getDefaultPathname());
};

export const isZISPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.ZIS_PAGE.test(pathname || getDefaultPathname());
};

export const isAccountPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.ACCOUNT_PAGE.test(pathname || getDefaultPathname());
};

export const isPageType = (pageRex: RegExp, pathname?: string) => {
  return pageRex.test(pathname || getDefaultPathname());
};

export const isMyOrdersPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.MY_ORDERS_PAGE.test(pathname || getDefaultPathname());
};

export const isOrderItemDetailsPage = (pathname: string) => {
  return PAGE_TYPE_REGEX.ORDER_ITEM_DETAILS_PAGE.test(pathname || getDefaultPathname());
};

export const getPageType = (path?: string) => {
  if (isServer() && !path) {
    throw new Error(
      'pathname must be provided to getPageType to be executable on the server-side.',
    );
  }

  const pathname = path || getDefaultPathname();

  if (isHomePage(pathname) || isSegmentPage(pathname)) {
    return SOURCE_PAGE_TYPE.Homepage;
  }

  if (isBrandsPage(pathname)) {
    return SOURCE_PAGE_TYPE.Brands;
  }

  if (isProductIndexPage(pathname)) {
    return SOURCE_PAGE_TYPE.PIP;
  }

  if (isCatalogPage(pathname)) {
    return SOURCE_PAGE_TYPE.Catalog;
  }

  if (isPDVPage(pathname)) {
    return SOURCE_PAGE_TYPE.ProductDetails;
  }

  if (isPDVNotFoundPage(pathname)) {
    return SOURCE_PAGE_TYPE.ProductDetailsNotFound;
  }

  if (isCartPage(pathname)) {
    return SOURCE_PAGE_TYPE.Cart;
  }

  if (isSearchPage(pathname)) {
    return SOURCE_PAGE_TYPE.Search;
  }

  if (isWishlistPage(pathname)) {
    return SOURCE_PAGE_TYPE.Wishlist;
  }

  if (isZISPage(pathname)) {
    return SOURCE_PAGE_TYPE.ZIS;
  }

  if (isOrderItemDetailsPage(pathname)) {
    return SOURCE_PAGE_TYPE.OrderItemDetails;
  }

  if (isMyOrdersPage(pathname)) {
    return SOURCE_PAGE_TYPE.MyOrders;
  }

  if (isAccountPage(pathname)) {
    return SOURCE_PAGE_TYPE.Account;
  }

  return undefined;
};
