import { SEGMENTS_LIST } from 'constants/segments';
import { Segment, Segments } from 'types/api/catalog/Segments';
import { getEnvConfiguration } from 'utils/env-configuration';
import { isObject } from 'utils/validation';
import { APIRequestConfig } from './APIClient';
import { ApiError } from './apiError';

/**
 * DOR api is using different approach for array param
 * example: foo = [1, 2]
 * -> the correct url request will be: foo=1&foo=2
 */
export const buildQueryString = <T extends Record<string, unknown>>(params: T) => {
  return Object.keys(params)
    .sort()
    .reduce((acc, key) => {
      if (Array.isArray(params[key])) {
        const value = params[key] as string[] | number[];

        acc.push(...value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`));
      }
      // If value is not empty
      else if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`);
      }

      return acc;
    }, [] as string[])
    .join('&');
};

export const mergeConfig = (...objects: APIRequestConfig[]) => {
  const res = objects.reduce((acc, arg) => {
    if (!arg) {
      return acc;
    }

    const result = { ...acc };

    Object.keys(arg).forEach((key) => {
      const property = key as keyof typeof arg;
      const value = arg[property];

      if ((property === 'body' && value instanceof FormData) || value instanceof AbortSignal) {
        result[property] = value;
      } else {
        result[property] = isObject(value)
          ? { ...(result[property] as APIRequestConfig), ...value }
          : value;
      }
    });

    return result;
  }, {});

  return res;
};

export const orderSegments = (data: Segments): Segment[] => {
  const countryCode = getEnvConfiguration('CC') || '';
  const segments = SEGMENTS_LIST[countryCode as keyof typeof SEGMENTS_LIST] || [];

  return segments.reduce((prev: Segment[], currentItem: string) => {
    if (!data[currentItem]) {
      return [...prev];
    }

    const item = {
      id: currentItem,
      name: data[currentItem],
    };

    return [...prev, item];
  }, [] as Segment[]);
};

export const createFormData = <T extends string | number | boolean | null | undefined>(
  data: Record<string, T | T[]>,
) => {
  return Object.entries(data).reduce((formData, [key, valueOrValues]) => {
    if (Array.isArray(valueOrValues)) {
      valueOrValues.forEach((value) => {
        formData.append(key, `${value}`);
      });
    } else {
      formData.append(key, `${valueOrValues}`);
    }

    return formData;
  }, new FormData());
};

export const removeEmptyParam = <T extends Record<string, unknown>>(obj: T) => {
  const result = { ...obj };

  Object.keys(result).forEach((key) => {
    if (result[key] === undefined || result[key] === null || result[key] === '') {
      delete result[key];
    }
  });

  return result;
};

export const getAPIError = async (response: Response) => {
  let backupResponse = null;

  try {
    backupResponse = response.clone();
    const { err_code: code, err_info: message } = await response.json();

    return new ApiError({
      message,
      code,
      status: response.status,
      url: response.url,
      type: response.type,
    });
  } catch (error) {
    const data = backupResponse ? await backupResponse.text() : undefined;

    return new ApiError({
      message: 'Response body is not json',
      data,
      status: response.status,
      url: response.url,
      type: response.type,
    });
  }
};
