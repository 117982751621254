import { RavenOptions, RavenStatic } from 'raven-js';
import { getEnvConfiguration } from 'utils/env-configuration';
import isServer from './is-server';

const SENTRY_DSN_KEY = 'SENTRY_DSN';

type Tag =
  /**
   * The error from get request
   */
  | 'get-request'
  /**
   * The error from submit request (POST/PUT)
   * which happen when users do some actions
   */
  | 'submit-request'
  /**
   * The error happen in handler function
   */
  | 'handler'
  /**
   * Core Web Vitals metrics
   */
  | 'core-web-vitals';

let Raven: RavenStatic | undefined = undefined;

export const initRaven = async () => {
  const dsn = getEnvConfiguration(SENTRY_DSN_KEY);

  if (!isServer() && dsn) {
    const revision = getEnvConfiguration('REVISION') || 'dev-local';
    const env = getEnvConfiguration('SENTRY_ENV') || 'dev';
    const cc = getEnvConfiguration('CC') || '';
    const config = {
      release: `${env}-${revision}-${cc}`,
      tags: {
        env,
        cc,
      },
      ignoreErrors: [
        // On Lotus, we don't use jquery anymore, all the errors are from 3rd party
        '$ is not defined',
        `Can't find variable: $`,
        // API already has monitoring, client don't need to monitor it.
        // if fail when submit request (POST/PUT), we manually capture it
        'Failed to fetch',
        // https://legacy.reactjs.org/docs/error-decoder.html
        /Minified React error #\d+/gm,
      ],
      // ignore error from external scripts
      ignoreUrls: ['www.googletagmanager.com', 'api.segment.io', 'optimizely.com'],
      // If any error happens, we will captured it at ErrorBoundary
      // with additional information, so disable auto capture here
      // to avoid duplicated error report
      captureUnhandledRejections: false,
      maxBreadcrumbs: 20,
    };

    try {
      let raven = (await import('raven-js')).default;

      raven = raven.config(dsn, config);
      raven = raven.install();

      setRavenInstance(raven);

      return raven;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to init Raven', error);
    }
  }
};

export const setRavenInstance = (instance: RavenStatic | undefined) => {
  Raven = instance;
};

export const getRavenInstance = async () => {
  return Raven ?? (await initRaven());
};

/**
 * Report error to Sentry
 *
 * Only report the error which we have to pay attention to avoid mess up the dashboard
 * @param message The error message: should something easy to understand and filter
 * @param options
 * @returns {boolean} true if the error is captured, false if not
 */
export const captureError = async (
  message: string,
  options: { error?: unknown; params?: unknown; tag: Tag },
) => {
  if (process.env.NODE_ENV === 'development') {
    return false;
  }

  const ravenInstance = await getRavenInstance();

  if (!ravenInstance) {
    return false;
  }

  ravenInstance.captureMessage(message, {
    extra: {
      error: options.error,
      params: options.params,
    },
    tags: {
      from: options.tag,
    },
  });

  return true;
};

/**
 * @returns {boolean} true if the error is captured, false if not
 */
export const captureException = async (error: unknown, options: RavenOptions) => {
  const ravenInstance = await getRavenInstance();

  if (!ravenInstance) {
    return false;
  }

  ravenInstance.captureException(error, options);

  return true;
};
