import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { getNewsLetter } from 'api/newsletter/getNewsLetter';
import useUser from './useUser';

const useNewsLetter = () => {
  const { data: user } = useUser();

  const newsLetterData = useSWRImmutable(user ? '/v1/newsletters' : null, getNewsLetter);

  useEffect(() => {
    const { data: newsLetter } = newsLetterData;

    // this is a hack for segment tracking.
    // for every segment tracking event, we have to attach user email subscription status to the event
    window.__NEWS_LETTER__ = newsLetter;
  }, [newsLetterData]);

  return newsLetterData;
};

export default useNewsLetter;
