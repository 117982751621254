const HEX_COLOR_PATTERN = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export function isEmptyObject<T extends object>(obj: Nullishable<T>): boolean {
  return !obj || Object.keys(obj).length === 0;
}

export function isObject<T extends Record<string, unknown>>(arg: unknown): arg is T {
  return typeof arg === 'object' && arg !== null && !Array.isArray(arg);
}

export const isArrayOfObjects = <T extends Record<string, unknown>>(arg: unknown): arg is T[] => {
  if (!Array.isArray(arg) || arg.length < 1) {
    return false;
  }

  return arg.every((item) => isObject(item));
};

export const isValidHexColor = (hexColor: string | null): hexColor is string => {
  return hexColor ? HEX_COLOR_PATTERN.test(hexColor) : false;
};

export function isLetter(str: string) {
  return str.length === 1 && new RegExp(/[a-z]/i).test(str);
}

export const isValidEmail = (email: string) => {
  return /^\S+@\S+\.\S+$/.test(email);
};
