import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { createJSONStorage, persist } from 'zustand/middleware';
import { localStorageKeys } from 'utils/local-storage';
import { DeliveryStore } from './types';

export const createDeliveryStore = () => {
  return createStore(
    persist(
      mutative<DeliveryStore>((set) => ({
        // Observables
        isHydrated: false,
        selectedLocation: null,
        shouldReselectLocation: false,

        // Actions
        setIsHydrated: (isHydrated) => {
          set((state) => {
            state.isHydrated = isHydrated;
          });
        },

        setSelectedLocation: (location) => {
          set((state) => {
            state.selectedLocation = location;
            state.shouldReselectLocation = false;
          });
        },

        setShouldReselectLocation: (shouldReselectLocation) => {
          set((state) => {
            state.shouldReselectLocation = shouldReselectLocation;
          });
        },
      })),
      {
        name: localStorageKeys.DELIVERY_LOCATION,
        partialize: (state) => ({ selectedLocation: state.selectedLocation }),
        storage: createJSONStorage(() => localStorage),

        onRehydrateStorage: () => (state) => {
          if (state) {
            state.setIsHydrated(true);
          }
        },
      },
    ),
  );
};
