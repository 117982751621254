import { ApiResponse, ZDTNewsletter } from '@zalora/doraemon-ts';
import apiClient from 'api/APIClient';
import { getAPIError } from '../util';

export const getNewsLetter = async () => {
  const response = await apiClient.get(`/v1/newsletters`, {
    credentials: 'include',
  });

  if (!response.ok) {
    throw await getAPIError(response);
  }

  const data: ApiResponse<ZDTNewsletter.Newsletters> = await response.json();

  return data.data.Newsletters;
};
