import Script from 'next/script';
import FeatureFlags from 'constants/feature-flags';
import { useEnabledBobFeatureFlag } from 'hooks/api/useEnabledBobFeatureFlag';
import { getEnvConfiguration } from 'utils/env-configuration';

export const OptimizelyClient = () => {
  const isEnabledOptimizelyClient = useEnabledBobFeatureFlag(
    FeatureFlags.Bob.LOTUS_OPTIMIZELY_CLIENT,
  );

  if (!isEnabledOptimizelyClient) {
    return null;
  }

  return (
    <Script
      src={`https://cdn.optimizely.com/js/${getEnvConfiguration('OPTIMIZELY_CLIENT_API_KEY')}.js`}
      strategy="afterInteractive"
    />
  );
};
