import { IncomingMessage, ServerResponse } from 'http';
import { CookieKey } from 'constants/cookies';
import { Env } from 'constants/env-configuration';
import { Routes } from 'constants/routes';
import Cookie, { getCookieDomain } from './cookie';
import { getDomainByLocale } from './domains';
import { getEnvConfiguration } from './env-configuration';
import isServer from './is-server';
import { isSegmentPage } from './page-type';

export const DEFAULT_SEGMENT = 'women';

export const redirectOnError = (req?: IncomingMessage, res?: ServerResponse, locale = '') => {
  const env = getEnvConfiguration('ENV');

  if (!env || env === Env.DEVELOPMENT) {
    return;
  }

  const isOnServer = isServer();

  // Should have early return if is on server and has no res to avoid
  // errors on cookie and redirection
  if (isOnServer && !res) {
    return;
  }

  const cookie = new Cookie(req, res);
  const currentUrl = isOnServer ? req?.url : window.location.pathname;
  const destination = getRedirectDestination(currentUrl, cookie);
  const errorBannerSource = cookie.get(CookieKey.ERROR_BANNER_SOURCE);

  // Won't redirect if destination matches previous page
  if (errorBannerSource && destination === errorBannerSource) {
    cookie.remove(CookieKey.ERROR_BANNER_SOURCE);

    return;
  }

  // Set cookie to show error banner
  const domain = isOnServer ? getDomainByLocale(locale) : window.location.origin;
  const cookieDomain = getCookieDomain(domain);

  cookie.set(CookieKey.ERROR_BANNER_SOURCE, currentUrl || '', undefined, cookieDomain);

  // Redirect
  if (isOnServer && !!res) {
    res.writeHead(302, { Location: destination });
    res.end();
  } else {
    window.location.href = destination;
  }
};

export const getRedirectDestination = (url: string | undefined, cookie: Cookie): string => {
  if (!url) {
    return '';
  }

  // Redirect to catalog page if segment page has error
  if (isSegmentPage(url)) {
    return '/c/all';
  }

  // Otherwise redirect to segment page
  const userSegment = cookie.get(CookieKey.USER_SEGMENT) || DEFAULT_SEGMENT;

  return `${Routes.SEGMENT}/${userSegment}`;
};

export const hasErrorBannerCookie = (req?: IncomingMessage, res?: ServerResponse) => {
  const cookie = new Cookie(req, res);
  const hasCookie = !!cookie.get(CookieKey.ERROR_BANNER_SOURCE);

  return hasCookie;
};

export const removeErrorBannerCookie = (req?: IncomingMessage, res?: ServerResponse) => {
  const cookie = new Cookie(req, res);

  cookie.remove(CookieKey.ERROR_BANNER_SOURCE);
};
