import { buildQueryString, mergeConfig } from 'api/util';
import { ApiResponse } from 'types/api/ApiResponse';
import { getLocaleByDomain } from 'utils/domains';
import { suffixUserAgent } from 'utils/e2e';
import { getEnvConfiguration } from 'utils/env-configuration';
import isServer from 'utils/is-server';
import { isEmptyObject } from 'utils/validation';

export const enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

export type QueryValue = string | number | boolean | string[] | number[];
export type QueryParams = Record<string, QueryValue>;

export const HEADERS = {
  CONTENT_LANG: 'Content-Language',
  USER_AGENT: 'User-Agent',
};

export interface APIRequestConfig extends RequestInit {
  baseURL?: string;
  params?: QueryParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  priority?: 'low' | 'high' | 'auto';
  /**
   * Timeout in millisecond
   */
  timeout?: number;
}

interface Handlers {
  getCustomConfig?: (config: Partial<APIRequestConfig>) => Partial<APIRequestConfig>;
  handleError: (error: unknown) => Promise<never>;
}

export class APIClient {
  private defaultConfig: APIRequestConfig = {};

  private handleErrorDefault(error: unknown) {
    return Promise.reject(error);
  }

  private handlers: Handlers = {
    handleError: this.handleErrorDefault,
  };

  private defaults: Partial<APIRequestConfig> = this.defaultConfig;

  constructor(config: APIRequestConfig, handlers: Handlers) {
    this.defaults = config;
    this.handlers = handlers;
  }

  private getFetchConfig(method: HttpMethod, config?: APIRequestConfig) {
    const getCustomConfig = this.handlers && this.handlers.getCustomConfig;
    let fetchConfig: Partial<APIRequestConfig> = {
      method,
    };

    fetchConfig = mergeConfig(fetchConfig, this.defaults, config || {});

    if (getCustomConfig) {
      const customConfig: APIRequestConfig = getCustomConfig({ ...fetchConfig });

      fetchConfig = mergeConfig(fetchConfig, customConfig);
    }

    return fetchConfig;
  }

  private getRoute(route: string, config?: APIRequestConfig): string {
    if (!config || isEmptyObject(config)) {
      return route;
    }

    const { params } = config;

    if (!params || isEmptyObject(params)) {
      return route;
    }

    return `${route}?${buildQueryString(params)}`;
  }

  public async sendRequest(method: HttpMethod, route: string, config?: APIRequestConfig) {
    return new Promise<Response>((resolve, reject) => {
      const fetchConfig = this.getFetchConfig(method, config);
      let timeoutId: ReturnType<typeof setTimeout>;

      if (config?.timeout) {
        timeoutId = setTimeout(() => reject('Timeout'), config?.timeout);
      }

      return fetch(`${this.defaults.baseURL}${route}`, fetchConfig)
        .then(resolve)
        .catch((err) => reject(err))
        .finally(() => timeoutId && clearTimeout(timeoutId));
    });
  }

  public get(route: string, config?: APIRequestConfig): Promise<Response> {
    return this.sendRequest(HttpMethod.GET, this.getRoute(route, config), config);
  }

  public post(route: string, config?: APIRequestConfig): Promise<Response> {
    return this.sendRequest(HttpMethod.POST, this.getRoute(route, config), config);
  }

  public put(route: string, config?: APIRequestConfig): Promise<Response> {
    return this.sendRequest(HttpMethod.PUT, this.getRoute(route, config), config);
  }

  public delete(route: string, config?: APIRequestConfig): Promise<Response> {
    return this.sendRequest(HttpMethod.DELETE, this.getRoute(route, config), config);
  }
}

const getInitHeaders = () => {
  const result: Record<string, string> = {
    Accept: 'application/json',
  };

  if (isServer()) {
    const revision = getEnvConfiguration('REVISION') || 'dev-local';
    let userAgent = `zalora/lotus-${revision}`;

    if (process.env.IS_PLAYWRIGHT === 'true') {
      userAgent = suffixUserAgent(userAgent);
    }

    result[HEADERS.USER_AGENT] = userAgent;
  } else {
    /**
     * init content-language
     * On server-side, content-language will be attached from next page lifecycle: getServerSideProps, getStaticProps
     * If not, DOR will decide fallback, we don't need to send default locale.
     *
     * On client-side, content-langue will be detect from url (hostname)
     */
    result[HEADERS.CONTENT_LANG] = getLocaleByDomain(location.hostname);
  }

  return result;
};

const apiClient = new APIClient(
  {
    /**
     * Always use process.env when running on server side
     * This will help middleware to get host
     */
    baseURL: isServer()
      ? process.env.DOR_API_HOST_INTERNAL
      : getEnvConfiguration('DOR_API_HOST_EXTERNAL'),

    headers: getInitHeaders(),
    /**
     * Default timeout is 30s
     * Any requests which expected slow, we can override by config when sending the request
     */
    timeout: 30000,
  },
  {
    handleError: (error: unknown) => {
      return Promise.reject(error);
    },
  },
);

/**
 * A fetcher which is never throw error
 *
 * The response will include
 * - status
 * - error
 * - data
 */
export const simpleFetch = async <T>(url: string, config?: APIRequestConfig) => {
  try {
    const res = await apiClient.get(url, config);
    const jsonRes: ApiResponse<T> = await res.json();

    return {
      status: res.status,
      data: jsonRes.data,
      error: undefined,
    };
  } catch (e: unknown) {
    return {
      data: undefined,
      error: e,
      status: 500,
    };
  }
};

export const authFetch = async <T>(api: string) => {
  const response = await apiClient.get(api, { credentials: 'include' });

  if (!response.ok) {
    return null;
  }

  const data = (await response.json()) as T;

  return data;
};

export default apiClient;
