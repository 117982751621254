import { useRouter } from 'next/router';
import { PAGES_TYPES } from 'constants/page-types';
import { RouteKey, Routes } from 'constants/routes';

export const getPageType = (pathname: string): PAGES_TYPES => {
  switch (pathname) {
    case Routes.CART: {
      return PAGES_TYPES.cart;
    }

    case Routes.CATALOG: {
      return PAGES_TYPES.catalog;
    }

    case Routes.ERROR: {
      return PAGES_TYPES.error;
    }

    case Routes.HOMEPAGE: {
      return PAGES_TYPES.homepage;
    }

    case Routes.LOGIN: {
      return PAGES_TYPES.login;
    }

    case Routes.PRODUCT: {
      return PAGES_TYPES.product;
    }

    case Routes.REGISTER: {
      return PAGES_TYPES.register;
    }

    case Routes.SEARCH: {
      return PAGES_TYPES.search;
    }

    case Routes.SEGMENT: {
      return PAGES_TYPES.segment;
    }

    case Routes.WISHLIST: {
      return PAGES_TYPES.wishlist;
    }

    case Routes.ZIS: {
      return PAGES_TYPES.zis;
    }

    default: {
      return PAGES_TYPES.unknown;
    }
  }
};

export const getRouteKeyByAsPath = (
  asPath: ReturnType<typeof useRouter>['asPath'],
): RouteKey | undefined => {
  // Sort routes to ensure routes like "/c" don't match "/customer"
  const sortedRoutes = Object.values(Routes).sort((a, b) => b.length - a.length);

  for (const route of sortedRoutes) {
    if (route === '/') {
      // Special case for single slash page "/". If we don't add this. The
      // default return value of this function will always be "/" because each
      // route starts with a "/".
      if (/^(\/[^\/\?]+).*/.test(asPath)) {
        continue;
      }
    }

    if (asPath.startsWith(route)) {
      return route;
    }
  }

  return undefined;
};
