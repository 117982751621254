import { ServerResponse, IncomingMessage } from 'http';
import { buildCookieValue, getCookieDomain, ICookie, parseCookie } from '.';

export default class ServerCookie implements ICookie {
  request?: IncomingMessage;
  response?: ServerResponse;
  cookies: Record<string, string | undefined> | null = null;

  constructor(req?: IncomingMessage, res?: ServerResponse) {
    this.request = req;
    this.response = res;
  }

  get(key: string): string | undefined {
    if (!this.cookies) {
      this.cookies = parseCookie(this.request?.headers?.cookie);
    }

    if (Object.keys(this.cookies).length === 0) {
      return undefined;
    }

    return this.cookies[key];
  }

  set = (key: string, value?: string, expires?: string, cookieDomain?: string): void => {
    if (!this.response) {
      throw new Error('Response not found');
    }

    const hostname = this.request?.headers?.host || '';
    const domain = cookieDomain || getCookieDomain(hostname);

    this.response.setHeader('set-cookie', buildCookieValue(key, value, expires, domain));

    // below code for caching added cookie
    if (this.cookies) {
      this.cookies[key] = value;
    }

    if (this.request) {
      this.request.headers.cookie = this.buildCookieString();
    }
  };

  remove = (key: string): void => {
    this.set(key);
  };

  buildCookieString(): string {
    if (!this.cookies) {
      return '';
    }

    return Object.keys(this.cookies)
      .map((key) => `${key}=${encodeURIComponent(this.cookies?.[key] || '')}`)
      .join('; ');
  }
}
