import { buildCookieValue, getCookieDomain, getTimeInThePast, ICookie, parseCookie } from '.';

export default class ClientCookie implements ICookie {
  get(key: string): string | undefined {
    const cookies = parseCookie(document.cookie);

    return cookies[key];
  }

  set(key: string, value?: string, expires?: string, cookieDomain?: string): void {
    const hostname = window.location.hostname || '';
    const domain = cookieDomain || getCookieDomain(hostname);
    const cookieValue = buildCookieValue(key, value, expires, domain);

    document.cookie = cookieValue;
  }

  remove(key: string): void {
    this.set(key, undefined, getTimeInThePast(), getCookieDomain(window.location.hostname));
  }
}
