import SCREEN_SIZES from 'constants/screen-size';
import {
  getScreenSize,
  getScreenSizeName,
  isDesktopScreenSize,
  isMobileScreenSize,
} from 'utils/screen-size';

export const getPlatformSource = () => {
  const screenSize = getScreenSize();

  if (isMobileScreenSize(screenSize)) {
    return 'Lotus Mobile Web';
  }

  if (screenSize.name === 'TABLET') {
    return 'Lotus Tablet Web';
  }

  if (screenSize.name === 'DESKTOP') {
    return 'Lotus Desktop Web';
  }
};

export const getNumCatalogItemPerRow = () => {
  const screenName = getScreenSizeName();

  switch (screenName) {
    case SCREEN_SIZES.SMOBILE.name:

    case SCREEN_SIZES.LMOBILE.name: {
      return 2;
    }

    case SCREEN_SIZES.TABLET.name: {
      return 4;
    }

    case SCREEN_SIZES.DESKTOP.name: {
      return 3;
    }

    default: {
      return 3;
    }
  }
};

export const getUtmSource = () => {
  const screenSize = getScreenSize();

  if (isDesktopScreenSize(screenSize)) {
    return 'dweb';
  }

  return 'mweb';
};

export const getNumWishlistItemPerRow = () => {
  const screenName = getScreenSizeName();

  switch (screenName) {
    case SCREEN_SIZES.SMOBILE.name:

    case SCREEN_SIZES.LMOBILE.name: {
      return 1;
    }

    case SCREEN_SIZES.TABLET.name: {
      return 4;
    }

    case SCREEN_SIZES.DESKTOP.name: {
      return 6;
    }

    default: {
      return 6;
    }
  }
};
