export const CookieKey = {
  USER_LANGUAGE: 'userLanguage',
  USER_SEGMENT: 'userSegment',
  ERROR_BANNER_SOURCE: 'error_banner_source',
  RESTORE_PASSWORD_SUCCESS: 'restore_password_success',
  UAID: 'zid',

  /**
   * Alita/Quicksilver (e.g. https://checkout.zalora.sg) can set a special
   * `LOTUS_OPEN_ADDRESS_DIALOG` cookie which will trigger the address
   * dialog to open on the Lotus cart page.
   */
  LOTUS_OPEN_ADDRESS_DIALOG: 'lotus_open_address_dialog',
  THREE_LAST_VIEWED_SKUS: 'three_last_viewed_skus',
};
