import { isObject } from './validation';

export const IGNORABLE_ERROR_NAME = 'IgnorableError';

/**
 * Errors that can be omitted from Raven log and would not cause redirection on error
 */
export class IgnorableError extends Error {
  constructor(msg: string) {
    super(msg);

    this.name = IGNORABLE_ERROR_NAME;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, IgnorableError.prototype);
  }
}

export const hasIgnorableError = (error: unknown) => {
  return isObject(error) && 'name' in error && error.name === IGNORABLE_ERROR_NAME;
};
