import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { FALSE } from 'constants/optimizely';
import { OptimizelyStore } from './types';

export const createOptimizelyStore = () => {
  return createStore(
    mutative<OptimizelyStore>((set) => ({
      // Observables
      isPriceDisplayEnabled: false,
      isSocialProofingEnabled: false,
      isBestPriceGuaranteeReady: false,
      bestPriceGuaranteeValue: FALSE,
      isSomethingSimilarPDVEnabled: false,
      priceDropData: {
        isEnabled: false,
        ctaLinks: {},
      },

      // Actions
      setIsPriceDisplayEnabled: (isEnabled) => {
        set((state) => {
          state.isPriceDisplayEnabled = isEnabled;
        });
      },

      setIsSocialProofingEnabled: (isEnabled) => {
        set((state) => {
          state.isSocialProofingEnabled = isEnabled;
        });
      },

      setIsBestPriceGuaranteeReady: (isReady) => {
        set((state) => {
          state.isBestPriceGuaranteeReady = isReady;
        });
      },

      setBestPriceGuaranteeValue: (value) => {
        set((state) => {
          state.bestPriceGuaranteeValue = value;
        });
      },

      setIsSomethingSimilarPDVEnabled: (isEnabled) => {
        set((state) => {
          state.isSomethingSimilarPDVEnabled = isEnabled;
        });
      },

      setPriceDropData: (priceDropData) => {
        set((state) => {
          state.priceDropData = priceDropData;
        });
      },
    })),
  );
};
