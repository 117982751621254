import { type Duration } from 'date-fns';
import add from 'date-fns/add';
import addHours from 'date-fns/addHours';
import addYears from 'date-fns/addYears';
import { ServerResponse, IncomingMessage } from 'http';
import { CookieKey } from 'constants/cookies';
import { getZaloraDomainWithoutSubdomain } from 'utils/domains';
import { getEnvConfiguration } from 'utils/env-configuration';
import isServer from 'utils/is-server';
import ClientCookie from './ClientCookie';
import ServerCookie from './ServerCookie';

const ZALORA_SUB_DOMAINS = ['www', 'zh', 'checkout', 'zh-checkout', 'bm'];

export interface ICookie {
  get: (key: string) => string | undefined;
  set: (key: string, value: string, expires?: string, cookieDomain?: string) => void;
  remove: (key: string) => void;
}

export default class Cookie implements ICookie {
  instance: ICookie;

  constructor(req?: IncomingMessage, res?: ServerResponse) {
    this.instance = isServer() ? new ServerCookie(req, res) : new ClientCookie();
  }

  get = (key: string): string | undefined => {
    return this.instance.get(key);
  };

  set = (key: string, value: string, expires?: string, cookieDomain?: string): void => {
    this.instance.set(key, value, expires, cookieDomain);
  };

  remove = (key: string): void => {
    this.instance.remove(key);
  };
}

export const parseCookie = (cookieStr?: string): Record<string, string> => {
  if (!cookieStr) {
    return {};
  }

  return cookieStr
    .split(';')
    .reduce((allCookies: ReturnType<typeof parseCookie>, currentCookie: string) => {
      /**
       * Split cookie by first instance of '=',
       * By using regex below it might generate an unexpected empty element, then we need to filter it out.
       */
      const cookieTuple = currentCookie.split(/=(.*)/s).filter(Boolean) as [string, string];

      if (cookieTuple.length === 2) {
        const [key, value] = cookieTuple;
        const cookieValue = value.trim();

        if (cookieValue) {
          allCookies[key.trim()] = decodeURIComponent(cookieValue);
        }
      }

      return allCookies;
    }, {});
};

export const buildCookieValue = (
  key: string,
  value?: string,
  expires?: string,
  cookieDomain?: string,
): string => {
  const env = getEnvConfiguration('ENV');
  const valueString = value || '';
  let cookieValue = `${key}=${valueString}; path=/;`;

  // on dev env (local), we can not set secure cookie
  if (env !== 'dev') {
    cookieValue = `${cookieValue} secure;`;
  }

  // if expires is not provided, it will be expired at the end of session
  if (expires) {
    cookieValue = `${cookieValue} expires=${expires};`;
  }

  if (cookieDomain !== '') {
    cookieValue = `${cookieValue} domain=${cookieDomain};`;
  }

  return cookieValue;
};

export const getCookieDomain = (url: string) => {
  if (!url || url === '') {
    return '';
  }

  const hostname = url.replace(/^(https|http)?:\/\//, '');
  const chunks = hostname.split('.');

  if (ZALORA_SUB_DOMAINS.includes(chunks[0])) {
    chunks.shift();
  }

  return chunks.join('.');
};

export const getTimeInThePast = (): string => {
  const expireTime = new Date();

  expireTime.setFullYear(expireTime.getFullYear() - 1);

  return expireTime.toUTCString();
};

export const getExpireTime = (duration: Duration): Date => {
  const expireTime = new Date();

  return add(expireTime, duration);
};

export const getExpireTimeByYears = (years: number): Date => {
  const expireTime = new Date();

  return addYears(expireTime, years);
};

export const durationByHours = (duration: number): Date => {
  const currentTime = new Date();

  return addHours(currentTime, duration);
};

export const setUserLanguageCookie = (language: string): void => {
  if (!language) {
    return;
  }

  const cookie = new Cookie();

  cookie.set(
    CookieKey.USER_LANGUAGE,
    language,
    undefined,
    getZaloraDomainWithoutSubdomain(window.location.host),
  );
};
