import { IncomingHttpHeaders } from 'http';
import isServer from './is-server';

const BOT_KEYWORDS = [
  // https://developers.google.com/search/docs/crawling-indexing/overview-google-crawlers
  'Googlebot',
  'Storebot-Google',
  'Google-InspectionTool',
  'AdsBot-Google',
  //https://www.bing.com/webmasters/help/which-crawlers-does-bing-use-8c184ec0
  'bingbot',
  // https://support.apple.com/en-sg/HT204683
  'Applebot',
];

export const getUserAgent = (headers?: IncomingHttpHeaders) => {
  if (isServer()) {
    return headers?.['user-agent'] || '';
  }

  return navigator.userAgent;
};

export const isSearchCrawler = (userAgent: string) => {
  if (!userAgent) {
    return false;
  }

  const agent = userAgent.toLowerCase();

  return BOT_KEYWORDS.some((bot) => agent.includes(bot.toLowerCase()));
};
