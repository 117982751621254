import { ZDTCheckout } from '@zalora/doraemon-ts';
import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { createJSONStorage, persist } from 'zustand/middleware';
import { localStorageKeys } from 'utils/local-storage';
import { createPromoSlice } from './promoSlice';
import { CartParams, CartStore } from './types';

export const createCartStore = () => {
  return createStore(
    persist(
      mutative<CartStore>((set, ...args) => ({
        // Observables
        isPersistentValuesRestored: false,
        isLoading: false,
        cartParams: {},

        // Actions
        setLoading: (status: boolean) => {
          set((state) => {
            state.isLoading = status;
          });
        },

        setRestored: (status: boolean) => {
          set((state) => {
            state.isPersistentValuesRestored = status;
          });
        },

        updateCartParams: (newParams: CartParams) => {
          set((state) => {
            state.cartParams = {
              ...state.cartParams,
              ...newParams,
            };
          });
        },

        setSelectedPopStation: (
          provider: ZDTCheckout.PickupProvider,
          location: ZDTCheckout.PickupLocation,
        ) => {
          set((state) => {
            state.cartParams = {
              ...state.cartParams,
              address: undefined,
              popStation: {
                location,
                provider,
              },
            };
          });
        },

        ...createPromoSlice()(set, ...args),
      })),

      // Persist options
      {
        name: localStorageKeys.CART_STORE,
        storage: createJSONStorage(() => sessionStorage),
        partialize: ({ cartParams, promoParams }) => ({ cartParams, promoParams }),
        onRehydrateStorage: () => (state) => {
          state?.setRestored(true);
        },
        version: new Date().getTime() + 2 * 3600 * 1000, // expired in 2 hrs
        // @ts-ignore
        migrate: (persistedState, version) => {
          if (new Date().getTime() > version) {
            return {};
          }

          return persistedState;
        },
      },
    ),
  );
};
