import { Router } from 'next/router';

export const PAGE_LOADER_ID = 'pageLoader';

export const init = () => {
  Router.events.on('routeChangeStart', () => {
    showLoader();
  });

  Router.events.on('routeChangeComplete', () => {
    hideLoader();
  });

  Router.events.on('routeChangeError', () => {
    hideLoader();
  });
};

const showLoader = () => {
  const loader = document.getElementById(PAGE_LOADER_ID);

  if (loader) {
    loader.removeAttribute('hidden');
  }
};

export const hideLoader = () => {
  const loader = document.getElementById(PAGE_LOADER_ID);

  if (loader) {
    loader.hidden = true;
  }
};
