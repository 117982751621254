import type { AppProps } from 'next/app';
import { ComponentType, lazy, Suspense } from 'react';

const DynamicOptimizelyProviderWrapper = lazy(() => import('context/OptimizelyProviderWrapper'));

const withOptimizelyContext = <Props extends AppProps = AppProps>(
  Component: ComponentType<Props>,
) => {
  const OptimizelyProviderWrapper = (props: Props) => {
    return (
      <Suspense fallback={null}>
        <DynamicOptimizelyProviderWrapper>
          <Component {...props} />
        </DynamicOptimizelyProviderWrapper>
      </Suspense>
    );
  };

  return OptimizelyProviderWrapper;
};

export default withOptimizelyContext;
